<template lang="pug">
  div
    base-snackbar(
      :errors.sync="hericaClientPostErrors"
    )
    v-row
      v-col(cols="12")
        v-text-field(
          label="Username"
          v-model="name"
          :error-messages="hericaClientPostErrors.name"
        )
          template(v-slot:append)
            base-tooltip(
              :color="$objectEmpty(infoErrors) ? 'primary' : 'error'"
              text
              x-small
              dark
              tooltip="Info"
              @click="openInfo = true"
              bottom
            )
              v-icon mdi-account-details
      v-col(cols="12")
        v-text-field(
          label="Email"
          type="email"
          v-model="email"
          :error-messages="hericaClientPostErrors.email"
        )
      v-col(cols="12")
        v-text-field(
          label="Password"
          type="password"
          v-model="password"
        )
      v-col(cols="12")
        v-switch(
          color="primary"
          label="Active"
          v-model="active"
        )
    v-btn(
      color="primary"
      dense
      small
      dark
      @click="sendRequest"
      :loading="hericaClientPosting"
    )
      span Save
    v-dialog(
      v-model="openInfo"
      eager
      width="800"
    )
      v-card
        v-toolbar(
          color="primary"
          dense
          elevation="0"
          dark
        )
          span Info
        v-container
          herica-client-info-form(
            v-model="info"
            :errors="infoErrors"
          )
</template>
<script>
import hericaClientRepository from '@/repositories/herica-client.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [hericaClientPostVars, hericaClientPostVarNames] = requestVars({ identifier: 'herica-client', request: 'post', hasData: false })
const hericaClientPostHandler = new VueRequestHandler(null, hericaClientPostVarNames)

const inputVars = () => ({
  id: null,
  name: null,
  email: null,
  password: 'hericadefault',
  active: true,
  info: {},
})

export default {
  name: 'CreateHericalClientInfo',
  props: {
    edit: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    hericaClientInfoForm: () => import('./info/HericaClientInfoForm'),
  },
  data () {
    return {
      ...hericaClientPostVars,
      ...inputVars(),
      openInfo: false,
    }
  },
  computed: {
    editMode () {
      return !this.$objectEmpty(this.edit)
    },
    infoErrors () {
      const regex = /info\./
      return Object.keys(this.hericaClientPostErrors)
        .filter(key => regex.test(key))
        .reduce((result, key) => {
          result[key.replace(regex, '')] = this.hericaClientPostErrors[key]
          return result
        }, {})
    },
  },
  methods: {
    sendRequest () {
      if (this.editMode) {
        this.updateHericaClient()
        return
      }
      this.storeHericaClient()
    },
    storeHericaClient () {
      const handler = hericaClientPostHandler
      const repository = hericaClientRepository.store
      const { data } = this.getInputVars()
      handler.setVue(this)
      handler.execute(repository, [data])
    },
    updateHericaClient () {
      const handler = hericaClientPostHandler
      const repository = hericaClientRepository.update
      const { id, data } = this.getInputVars()
      handler.setVue(this)
      handler.execute(repository, [id, data])
    },
    getInputVars () {
      const excluded = ['id']
      let data = Object.keys(inputVars())
        .filter(key => !excluded.includes(key))
        .reduce((result, key) => {
          result[key] = this[key]
          return result
        }, {})
      data = Window.objectChangeKeyCase(data, 'camelToSnakeCase')
      const { id } = this
      return { data, id }
    },
  },
}
</script>